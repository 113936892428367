'use client';

import {
  LDProvider,
  reactSdkContextFactory,
} from 'launchdarkly-react-client-sdk';
import { type PropsWithChildren } from 'react';

import { useUser } from './useUser';

interface EONLDProviderProps extends PropsWithChildren {
  clientId?: string;
}

export const flagsContext = reactSdkContextFactory();

export const EONLDProvider = (props: EONLDProviderProps) => {
  const { currentProjectId } = useUser();
  if (!currentProjectId) {
    return props.children;
  }

  if (!props.clientId) {
    return (
      <flagsContext.Provider
        value={{
          flags: {
            dataAccessRoles: true,
            azureSourceAccount: true,
            assignBackupPolicyToSingleResource: true,
            enableServiceAccounts: true,
          },
          flagKeyMap: {},
          ldClient: undefined,
        }}
      >
        {props.children}
      </flagsContext.Provider>
    );
  }

  return (
    <LDProvider
      reactOptions={{
        reactContext: flagsContext,
      }}
      clientSideID={props.clientId}
      deferInitialization={true}
      context={{
        key: currentProjectId || '',
      }}
      options={
        {
          /* ... */
        }
      }
    >
      {props.children}
    </LDProvider>
  );
};
